export const MARG_ORDER_STATUS = {
  CREATED: 'CREATED',
  CONFIRMED: 'CONFIRMED',
  DELETED: 'DELETED',
  PICKED: 'PICKED',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
}

export const BILL_RG_STATUS_STATUS_OPTIONS = {
  CREATED: 'Created',
  PACKING_INITIATED: 'Packing Initiated',
  PACKING_COMPLETED: 'Packing Completed',
}

export const deliveryModeOptions = [
  {
    id: 'SELF_COLLECTION',
    text: 'Self Collection',
  },
  {
    id: 'DOOR_DELIVERY',
    text: 'Door Delivery',
  },
  {
    id: 'TRANSPORT_DELIVERY',
    text: 'Transport Delivery',
  },
  {
    id: 'MR_COLLECTION',
    text: 'Mr Collection',
  },
  {
    id: 'DELIVERED_TO_OTHER_WHOLESALER',
    text: 'Delivered to Other Wholesaler',
  },
  {
    id: 'DELIVERED_TO_OTHER_RETAILER',
    text: 'Delivered to Other Retailer',
  },
  {
    id: 'DELIVERED_WITH_REMARK',
    text: 'Delivered with Remark',
  },
]
