<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title> Access management </b-card-title>
      <b-alert
        variant="danger"
        :show="errorMessage ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </b-alert>
      <div
        v-if="loadingOptions"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>
      <b-row v-else>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group label="Retailer">
            <custom-v-select
              v-model="selectedUser"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="userOptions"
              label="fullname"
              placeholder="Select User"
              :reduce="(data) => data.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div
        v-if="loadingUserData"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>
      <b-row v-if="selectedUser && !loadingUserData">
        <b-col cols="12">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Areas">
                <custom-v-select
                  v-model="accessData.allocated_areas"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="areas"
                  label="text"
                  multiple
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  placeholder="Select Areas"
                  :reduce="(data) => data.id"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Buyers">
                <custom-v-select
                  v-model="accessData.allocated_buyers"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="buyers"
                  label="text"
                  multiple
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  placeholder="Select Buyers"
                  :reduce="(data) => data.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end">
            <b-button
              variant="primary"
              :disabled="submittingUserData"
              @click="onUpdate"
            >
              Update
              <b-spinner
                v-if="submittingUserData"
                small
                label="Small Spinner"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    CustomVSelect,
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BButton,
    BAlert,
  },
  data() {
    return {
      submittingUserData: false,
      selectedUser: null,
      userOptions: [],
      areas: [],
      buyers: [],
      accessData: {},
      errorMessage: null,
      loadingOptions: false,
      loadingUserData: false,
    }
  },
  watch: {
    selectedUser(newVal) {
      if (newVal) {
        this.fetchUserById(newVal)
      }
    },
  },
  mounted() {
    this.fetchInitialData()
  },
  methods: {
    async fetchInitialData() {
      try {
        this.loadingOptions = true
        const { data } = await axios.get('/delivery/delivery_group_users')
        this.userOptions = data

        const { data: areaOptionRes } = await axios.get(
          '/accounts/areas_options',
        )
        this.areas = areaOptionRes.data

        const { data: buyerOptionRes } = await axios.get('/accounts/buyers_options')
        this.buyers = buyerOptionRes.data
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Error fetching options!'
      } finally {
        this.loadingOptions = false
      }
    },
    async fetchUserById(id) {
      try {
        this.loadingUserData = true
        const { data = {} } = await axios.get('/delivery/delivery_access_detail/', {
          params: {
            user: id,
          },
        })
        this.accessData = {
          allocated_areas: data.allocated_areas || [],
          allocated_buyers: data.allocated_buyers || [],
        }
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Error fetching user data!'
      } finally {
        this.loadingUserData = false
      }
    },
    async onUpdate() {
      this.submittingUserData = true

      const payload = {
        ...this.accessData,
        user: this.selectedUser,
      }

      try {
        await axios.post('/delivery/delivery_access_detail/', payload)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Access Updated Successfully!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.errorMessage = ''
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Error updating user data'
      } finally {
        this.submittingUserData = false
      }
    },
  },
}
</script>
