<template>
  <b-card no-body>
    <b-card-header>
      <h4>Retailer Tickets</h4>
    </b-card-header>
    <b-card-body>
      <retailer-ticket-filters @filtersUpdated="filtersChangeHandler" />
      <retailer-ticket-list :filters="filters" />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody } from 'bootstrap-vue'
import RetailerTicketList from '@/components/Retailers/Tickets/RetailerTicketList.vue'
import RetailerTicketFilters from '@/components/Retailers/Tickets/RetailerTicketFilters.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    RetailerTicketList,
    RetailerTicketFilters,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
