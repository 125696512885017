<template>
  <b-card title="Orders">
    <orders-filters @filtersUpdated="filtersChangeHandler" />
    <orders-list
      :filters="filters"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import OrdersList from '@/components/Retailers/Orders/OrdersList.vue'
import OrdersFilters from '@/components/Retailers/Orders/OrdersFilters.vue'
import dateMixin from '@/mixins/dateMixin'

export default {
  components: {
    BCard,
    OrdersList,
    OrdersFilters,
  },
  mixins: [dateMixin],
  data() {
    return {
      filters: {
        created_date_from: this.formatedDate(new Date(), 'YYYY-MM-DD'),
        created_date_to: this.formatedDate(new Date(), 'YYYY-MM-DD'),
      },
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
