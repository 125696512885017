<template>
  <b-row>
    <b-col md="2">
      <b-form-group
        label="Ticket ID"
      >
        <b-form-input
          v-model="ticketId"
          type="text"
          placeholder="Search Ticket ID"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Buyers"
      >
        <custom-v-select
          v-model="selectedBuyers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="buyersOptions"
          label="text"
          placeholder="Select Buyers"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Created on Range"
      >
        <flat-picker
          v-model="dateRange"
          :config="{ mode: 'range'}"
          placeholder="Select Date Range"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Closed On Range"
      >

        <flat-picker
          v-model="closedOnDateRange"
          :config="{ mode: 'range'}"
          placeholder="Select Date Range"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Status"
      >
        <custom-v-select
          v-model="selectedStatusOption"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusOptions"
          label="text"
          placeholder="Select Option"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <DiscrepancyTypeFilter
        v-model="discrepancyType"
        :options="discrepancies"
        @input="filtersChangeHandler"
      />
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Departments"
      >
        <custom-v-select
          v-model="departments"
          multiple
          :options="displayDepartmentOptions"
          label="text"
          placeholder="Select Departments"
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import DiscrepancyTypeFilter from '@/components/Tickets/DiscrepancyTypeFilter.vue'
import { buyerTicketDiscrepancies } from '@/components/Tickets/TicketDiscrepancy/discrepancy-types'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    CustomVSelect,
    FlatPicker,
    DiscrepancyTypeFilter,
  },
  data() {
    return {
      ticketId: null,
      selectedBuyers: [],
      buyersOptions: [],
      dateRange: null,
      closedOnDateRange: null,
      selectedStatusOption: null,
      statusOptions: [
        { text: 'Open', value: 'Open' },
        { text: 'In Progress', value: 'In Progress' },
        { text: 'Closed', value: 'Closed' },
      ],
      discrepancyType: [],
      departments: [],
      departmentOptions: [],
      discrepancies: buyerTicketDiscrepancies,
    }
  },
  computed: {
    displayDepartmentOptions() {
      return this.departmentOptions.map(option => ({
        id: option,
        text: option.charAt(0).toUpperCase() + option.slice(1),
      }))
    },
  },
  watch: {
    dateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
    closedOnDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    async fetchOptions() {
      const buyersResponse = await axios.get('/accounts/buyers_options')
      this.buyersOptions = buyersResponse.data.data

      const departmentResponse = await axios.get('/tickets/department_options')
      this.departmentOptions = departmentResponse.data.departments
    },
    filtersChangeHandler() {
      const filtersdata = {}
      if (this.ticketId) {
        filtersdata.ticket_id = this.ticketId
      }

      if (this.selectedBuyers.length > 0) {
        filtersdata.buyers = this.selectedBuyers.map(item => item.id)
      }

      if (this.dateRange) {
        const [dateFrom, dateTo] = this.dateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.created_date_from = dateFrom
          filtersdata.created_date_to = dateTo
        }
      }
      if (this.closedOnDateRange) {
        const [closedOnDateFrom, closedOnDateTo] = this.closedOnDateRange.split(' to ')
        if (closedOnDateFrom && closedOnDateTo) {
          filtersdata.closed_on_from = closedOnDateFrom
          filtersdata.closed_on_to = closedOnDateTo
        }
      }

      if (this.selectedStatusOption) {
        filtersdata.status = this.selectedStatusOption.value
      }

      if (this.discrepancyType.length > 0) {
        filtersdata.discrepancy_types = this.discrepancyType
      }

      if (this.departments.length > 0) {
        filtersdata.departments = this.departments.map(dept => dept.id)
      }

      this.$emit('filtersUpdated', filtersdata)
    },
  },
}
</script>
