import Vue from 'vue'
import VueRouter from 'vue-router'
import multiguard from 'vue-router-multiguard'

import auth from '../middleware/auth'
import guest from '../middleware/guest'
import groupAccess from '../middleware/groupAccess'
import defaultRoute from '../middleware/defaultRoute'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      beforeEnter: multiguard([guest]),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      beforeEnter: multiguard([auth, defaultRoute]),
      meta: {},
    },
    {
      path: '/crete-order',
      name: 'create-order',
      component: () => import('@/views/Orders/CreateOrder.vue'),
      beforeEnter: multiguard([auth, groupAccess('orders')]),
      meta: {},
    },
    {
      path: '/manual-order',
      name: 'manual-order',
      component: () => import('@/views/Orders/ManualOrder.vue'),
      beforeEnter: multiguard([auth, groupAccess('orders')]),
      meta: {},
    },
    {
      path: '/orders-uploads',
      name: 'orders-uploads',
      component: () => import('@/views/Orders/Uploads.vue'),
      beforeEnter: multiguard([auth, groupAccess('orders')]),
      meta: {},
    },
    {
      path: '/purchase-orders',
      name: 'purchase-orders',
      component: () => import('@/views/Orders/PurchaseOrders.vue'),
      beforeEnter: multiguard([auth, groupAccess('orders')]),
      meta: {},
    },
    {
      path: '/purchase-order-summary',
      name: 'purchase-order-summary',
      component: () => import('@/views/Orders/PurchaseOrderSummary.vue'),
      beforeEnter: multiguard([auth, groupAccess('orders')]),
      meta: {},
    },
    {
      path: '/uploads',
      name: 'uploads',
      component: () => import('@/views/Accounts/Uploads.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Accounts/Dashboard.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/statements',
      name: 'statements',
      component: () => import('@/views/Accounts/Statements.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/print-statements',
      name: 'print-statements',
      component: () => import('@/views/Accounts/PrintStatements.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/credit-debit-notes',
      name: 'credit-debit-notes',
      component: () => import('@/views/Accounts/CreditDebitNotes.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/defualt-parties',
      name: 'defualt-parties',
      component: () => import('@/views/Accounts/DefaultParties.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/party-ledger',
      name: 'party-ledger',
      component: () => import('@/views/Accounts/PartyLedger.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/cheques',
      name: 'cheques',
      component: () => import('@/views/Accounts/Cheques.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/outstanding-report',
      name: 'outstanding-report',
      component: () => import('@/views/Accounts/OutstandingReport.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/sales-schedule',
      name: 'sales-schedule',
      component: () => import('@/views/Accounts/SalesSchedule.vue'),
      beforeEnter: multiguard([auth, groupAccess('sales')]),
      meta: {},
    },
    {
      path: '/sales-targets',
      name: 'sales-targets',
      component: () => import('@/views/Accounts/SalesTargets.vue'),
      beforeEnter: multiguard([auth, groupAccess('accounts')]),
      meta: {},
    },
    {
      path: '/todays-bills',
      name: 'todays-bills',
      component: () => import('@/views/Accounts/TodaysBills.vue'),
      beforeEnter: multiguard([auth, groupAccess('sales')]),
      meta: {},
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/Sales/Orders.vue'),
      beforeEnter: multiguard([auth, groupAccess('sales')]),
      meta: {},
    },
    {
      path: '/monthly-targets',
      name: 'monthly-targets',
      component: () => import('@/views/Sales/MonthlyTargets.vue'),
      beforeEnter: multiguard([auth, groupAccess('sales')]),
      meta: {},
    },
    {
      path: '/supplier-return',
      name: 'supplier-return',
      component: () => import('@/views/Returns/SupplierReturn.vue'),
      beforeEnter: multiguard([auth, groupAccess('returns')]),
      meta: {},
    },
    {
      path: '/supplier-return-summary',
      name: 'supplier-return-summary',
      component: () => import('@/views/Returns/SupplierReturnSummary.vue'),
      beforeEnter: multiguard([auth, groupAccess('returns')]),
      meta: {},
    },
    {
      path: '/buyer-return',
      name: 'buyer-return',
      component: () => import('@/views/Returns/BuyerReturn.vue'),
      beforeEnter: multiguard([auth, groupAccess('returns')]),
      meta: {},
    },
    {
      path: '/bilty',
      name: 'bilty',
      component: () => import('@/views/Invoices/Bilty.vue'),
      beforeEnter: multiguard([auth, groupAccess('invoices')]),
      meta: {},
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/Invoices/Invoices.vue'),
      beforeEnter: multiguard([auth, groupAccess('invoices')]),
      meta: {},
    },
    {
      path: '/supplier-tickets',
      name: 'supplier-tickets',
      component: () => import('@/views/Tickets/SupplierTickets.vue'),
      beforeEnter: multiguard([auth, groupAccess('tickets')]),
      meta: {},
    },
    {
      path: '/buyer-tickets',
      name: 'buyer-tickets',
      component: () => import('@/views/Tickets/BuyerTickets.vue'),
      beforeEnter: multiguard([auth, groupAccess('tickets')]),
      meta: {},
    },
    {
      path: '/purchase-items-stock',
      name: 'purchase-items-stock',
      component: () => import('@/views/Stocks/PurchaseItemStock.vue'),
      beforeEnter: multiguard([auth, groupAccess('stocks')]),
    },
    {
      path: '/verified-stock-reports',
      name: 'verified-stock-reports',
      component: () => import('@/views/Stocks/VerifiedStockReports.vue'),
      beforeEnter: multiguard([auth, groupAccess('stocks')]),
    },
    {
      path: '/delivery-access-management',
      name: 'delivery-access-management',
      component: () => import('@/views/Delivery/AccessManagement.vue'),
      beforeEnter: multiguard([auth, groupAccess('delivery_admin')]),
    },
    {
      path: '/delivery-status',
      name: 'delivery-status',
      component: () => import('@/views/Delivery/DeliveryStatus.vue'),
      beforeEnter: multiguard([auth, groupAccess('delivery_admin')]),
    },
    {
      path: '/delivery-dashboard',
      name: 'delivery-dashboard',
      component: () => import('@/views/Delivery/DeliveryDashboard.vue'),
      beforeEnter: multiguard([auth, groupAccess('delivery_admin')]),
    },
    {
      path: '/bus-master',
      name: 'bus-master',
      component: () => import('@/views/Delivery/BusMaster.vue'),
      beforeEnter: multiguard([auth, groupAccess('delivery_admin')]),
    },
    {
      path: '/retailer-access-management',
      name: 'retailer-access-management',
      component: () => import('@/views/Retailers/RetailerAccessManagement.vue'),
      beforeEnter: multiguard([auth, groupAccess('retailer_admin')]),
    },
    {
      path: '/retailer-tickets',
      name: 'retailer-tickets',
      component: () => import('@/views/Retailers/RetailerTickets.vue'),
      beforeEnter: multiguard([auth, groupAccess('retailer_admin')]),
    },
    {
      path: '/retailer-orders',
      name: 'retailer-orders',
      component: () => import('@/views/Retailers/RetailerOrders.vue'),
      beforeEnter: multiguard([auth, groupAccess('retailer_admin')]),
    },
    {
      path: '/rack-group-reports',
      name: 'rack-group-reports',
      component: () => import('@/views/Operations/RackGroupReports.vue'),
      beforeEnter: multiguard([auth, groupAccess('operations')]),
    },
    {
      path: '/verify-order-items',
      name: 'verify-order-items',
      component: () => import('@/views/Operations/VerifyOrderItems.vue'),
      beforeEnter: multiguard([auth, groupAccess('operations')]),
    },
    {
      path: '/packing',
      name: 'packing',
      component: () => import('@/views/Operations/Packing.vue'),
      beforeEnter: multiguard([auth, groupAccess('operations')]),
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
