<template>
  <div>
    <div
      v-if="!loadingError"
      class="text-right mb-2"
    >
      <b-button
        variant="outline-primary"
        class="refresh-btn mx-1"
        :disabled="loading"
        @click="fetchItems"
      >
        Refresh
      </b-button>
      <b-button
        variant="outline-primary"
        :disabled="!selectedItems.length"
        @click="displayReCreateModal = true"
      >
        Re-create on Marg
      </b-button>
    </div>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <template v-if="!loading && !loadingError">
      <b-table
        :items="results"
        :fields="tableFields"
        responsive="sm"
        striped
        hover
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        class="compact-table"
        no-local-sorting
        show-empty
        empty-text="No Records to display!"
        @sort-changed="sortingChanged"
      >
        <template #head(select)>
          <b-form-checkbox
            v-model="isAllItemsSelected"
            :disabled="results.length === 0"
            @change="handleAllItemsSelectChange"
          />
        </template>
        <template #cell(select)="value">
          <b-form-checkbox
            v-model="selectedItems"
            :value="value.item.id"
            name="select-status"
          />
        </template>
        <template #cell(total_items)="data">
          {{ formateNumber(data.item.total_items) }}
        </template>
        <template #cell(total_amount)="data">
          {{ formateNumber(data.item.total_amount) }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at ? formatedDate(data.item.created_at, 'DD-MM-YYYY LT') : '-' }}
        </template>
        <template #cell(status)="value">
          <StatusChip :data="value.item.status" />
        </template>
        <template #cell(bill_no)="data">
          {{ data.item.bill_info ? data.item.bill_info.bill.bill_no : '' }}
        </template>
        <template #cell(bill_amount)="data">
          {{ data.item.bill_info ? formateNumber(data.item.bill_info.bill.amount) : '' }}
        </template>
        <template #cell(actions)="data">
          <feather-icon
            v-b-tooltip.hover="{boundary:'window'}"
            title="View Details"
            icon="EyeIcon"
            size="20"
            class="cursor-pointer mr-1"
            @click="displayDetails(data.item.id)"
          />
        </template>
      </b-table>

      <detailed-pagination
        :total-items="totalItems"
        :per-page="perPage"
        :current-page="currentPage"
        @page-changed="(page) => {currentPage = page}"
      />
    </template>

    <order-details
      v-if="displayOrderDetailsId"
      :item-id="displayOrderDetailsId"
      @modal-closed="displayOrderDetailsId=null"
    />

    <recreate-on-marg-modal
      v-if="displayReCreateModal"
      :item-ids="selectedItems"
      @modal-closed="displayReCreateModal = false"
      @updated="onRecreateUpdate"
    />

  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, VBTooltip, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import StatusChip from '@/components/UI/StatusChip.vue'
import OrderDetails from './OrderDetails.vue'
import RecreateOnMargModal from './RecreateOnMargModal.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    OrderDetails,
    DetailedPagination,
    StatusChip,
    BButton,
    BFormCheckbox,
    RecreateOnMargModal,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      results: [],
      tableFields: [
        { key: 'select' },
        { key: 'order_number', label: 'Order Number', sortable: true },
        { key: 'marg_order_id', label: 'Marg Order ID', sortable: true },
        { key: 'party__identifier', label: 'Pary', sortable: true },
        {
          key: 'total_items', label: 'Total Items', sortable: true, class: 'text-right pr-2',
        },
        {
          key: 'total_amount', label: 'Order Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'placed_by__fullname', label: 'Placed By', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'bill_no', label: 'Bill No.' },
        { key: 'bill_amount', label: 'Bill Amount', class: 'text-right pr-2' },
        { key: 'remarks', label: 'Remarks', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'created_at',
      sortDesc: true,
      displayOrderDetailsId: null,
      selectedItems: [],
      isAllItemsSelected: false,
      displayReCreateModal: false,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
    selectedItems(newVal) {
      if (this.results.length > 0 && newVal.length === this.results.length) {
        this.isAllItemsSelected = true
      } else {
        this.isAllItemsSelected = false
      }
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true
      axios.post('/marg/filtered_orders',
        {
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          sort_desc: this.sortDesc,
          retailers_only: true,
          filters: {
            ...this.filters,
          },
        })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loadingError = error?.response?.data?.error || 'Error fetching order items!'
          this.loading = false
        })
    },
    displayDetails(itemId) {
      this.displayOrderDetailsId = itemId
    },
    handleAllItemsSelectChange(isChecked) {
      this.selectedItems = isChecked
        ? this.results.map(data => data.id)
        : []
    },
    onRecreateUpdate() {
      this.selectedItems = []
      this.fetchItems()
    },
  },
}
</script>
