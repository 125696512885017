<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <missing-area-widget />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <missing-bills :need-refresh="isRefreshMissingBills" />
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-body>
        <b-card-title> Delivery Status </b-card-title>
        <Filters v-model="filters" />
        <div
          v-if="!(loadingError)"
          class="text-right mb-2"
        >
          <b-button
            variant="outline-primary"
            class="mr-1 delivery-status-refresh-btn"
            :class="isNewItemAvailable ? 'pr-3' : ''"
            :disabled="loadingData"
            @click="handleRefresh"
          >
            Refresh
            <span
              v-if="isNewItemAvailable"
              class="badge badge-up badge-pill badge-success badge-minimal badge-glow"
            />
          </b-button>
          <b-button
            variant="outline-primary"
            :disabled="!selectedBills.length"
            @click="handleMarkAsPick"
          >
            Mark As Picked
          </b-button>
          <b-button
            variant="outline-primary"
            :disabled="!selectedBills.length"
            class="ml-1"
            @click="handleMarkAsDeliver"
          >
            Mark As Delivered
          </b-button>
          <b-button
            variant="outline-primary"
            :disabled="!selectedBills.length"
            class="ml-1"
            @click="handlePickAndDeliver"
          >
            Mark As Pick + Deliver
          </b-button>
        </div>
        <b-alert
          variant="danger"
          :show="errorMessage ? true : false"
        >
          <div
            v-if="typeof errorMessage === 'object'"
            class="alert-body"
          >
            <p
              v-for="(message, index) in errorMessage"
              :key="index"
            >
              {{ message }}
            </p>
          </div>
          <p
            v-else
            class="alert-body"
          >
            {{ errorMessage }}
          </p>
        </b-alert>
        <div
          v-if="loadingData"
          class="text-center"
        >
          <b-spinner
            label="Small Spinner"
            variant="primary"
          />
        </div>
        <b-table
          v-if="!(loadingData || loadingError)"
          responsive
          striped
          hover
          :items="parsedBills"
          :fields="tableFields"
          class="compact-table"
          no-local-sorting
          show-empty
          empty-text="No Records to display!"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="sortingChanged"
        >
          <template #head(select)>
            <b-form-checkbox
              v-model="isAllBillSelected"
              :disabled="results.length === 0"
              @change="handleAllBillSelectChange"
            />
          </template>
          <template #cell(bill_no)="{item}">
            <span class="d-flex align-items-center">
              {{ item.bill_no }}
              <span
                v-if="item.is_modified"
                class="is-modified-bill"
              />
              <span
                v-else
                class="modified-placeholder"
              />
              <span
                v-if="item.is_urgent"
                class="is-urgent-bill"
              />
              <feather-icon
                v-if="item.delivery_otp && item.delivery_otp.verified"
                v-b-tooltip.hover="{boundary:'window'}"
                title="OTP Verified"
                icon="CheckIcon"
                size="16"
                class="text-primary mx-1"
              />
            </span>
          </template>
          <template #cell(select)="value">
            <b-form-checkbox
              v-model="selectedBills"
              :value="value.item.id"
              name="select-status"
            />
          </template>
          <template #cell(amount)="data">
            {{ formateNumber(data.item.amount) }}
          </template>
          <template #cell(status)="value">
            <status :data="value.item.status" />
          </template>
          <template #cell(generated_at)="value">
            {{ value.item.generated_at && formatedDate(value.item.generated_at, 'DD-MM-YYYY LT') || '-' }}
          </template>
          <template #cell(bill_attachments)="value">
            <span
              v-for="(item, index) in value.item.bill_attachments"
              :key="index"
            >
              <a
                v-if="index === value.item.bill_attachments.length - 1"
                :href="item.file"
                target="_blank"
              >
                <span>View File {{ index + 1 }}</span>
              </a>
            </span>
          </template>

          <template #cell(action)="data">
            <feather-icon
              v-b-tooltip.hover="{boundary:'window'}"
              title="View Details"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer mr-1"
              @click="handleViewClick(data.item.bill_id)"
            />
          </template>
          <template #cell(user)="data">
            <span>{{ data.item.user || '-' }}</span>
          </template>
        </b-table>
        <detailed-pagination
          v-if="!loadingData"
          :total-items="totalItems"
          :per-page="perPage"
          :current-page="currentPage"
          @page-changed="handlePageChange"
        />
      </b-card-body>
    </b-card>
    <view-details-modal
      v-if="selectedViewId"
      v-model="showModal"
      :bill-id="selectedViewId"
      @modal-closed="handleCloseModal"
      @onStatusChange="fetchData"
    />
    <update-status-modal
      v-if="confirmPickModal"
      v-model="confirmPickModal"
      mode="PICK"
      :bill-ids="selectedBills"
      @updated="onStatusUpdate"
    />
    <update-status-modal
      v-if="confirmDeliverModal"
      v-model="confirmDeliverModal"
      mode="DELIVER"
      :bill-ids="selectedBills"
      :bus-options="busOptions"
      @updated="onStatusUpdate"
    />
    <update-status-modal
      v-if="confirmPickAndDeliverModal"
      v-model="confirmPickAndDeliverModal"
      mode="PICK+DELIVER"
      :bill-ids="selectedBills"
      :bus-options="busOptions"
      @updated="onStatusUpdate"
    />
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BTable,
  BAlert, BSpinner, BFormCheckbox, VBTooltip, BButton, BRow, BCol,
} from 'bootstrap-vue'
import axios from 'axios'
import Filters from '@/components/Delivery/DeliveryStatus/Filters.vue'
import getParsedQueryString from '@/utils/headerQueryParser'
import ViewDetailsModal from '@/components/Delivery/DeliveryStatus/ViewDetailsModal.vue'
import Status from '@/components/Delivery/DeliveryStatus/Status.vue'
import dateMixin from '@/mixins/dateMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import MissingAreaWidget from '@/components/Dashboard/MissingAreaWidget.vue'
import MissingBills from '@/components/Delivery/DeliveryStatus/MissingBills.vue'
import UpdateStatusModal from '@/components/Delivery/DeliveryStatus/UpdateStatusModal.vue'
import WS, { WS_ROOMS, WS_EVENTS } from '@/utils/ws'
import bus from '@/bus'
import formatMixin from '@/mixins/formatMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BAlert,
    BSpinner,
    BFormCheckbox,
    Filters,
    BButton,
    ViewDetailsModal,
    Status,
    DetailedPagination,
    MissingAreaWidget,
    MissingBills,
    BRow,
    BCol,
    UpdateStatusModal,
  },
  mixins: [dateMixin, formatMixin],
  data() {
    return {
      tableFields: [
        { key: 'select', label: '' },
        { key: 'bill_no', label: 'Bill Number', sortable: true },
        { key: 'party__identifier', label: 'Buyer Name', sortable: true },
        { key: 'bill_attachments', label: 'Attachments' },
        { key: 'generated_at', label: 'Date', sortable: true },
        { key: 'mode', label: 'Mode', sortable: true },
        {
          key: 'amount', label: 'Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'status', label: 'Status' },
        { key: 'user', label: 'User' },
        { key: 'action' },
      ],
      results: [],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'bill_no',
      sortDesc: true,
      loadingData: false,
      errorMessage: null,
      loadingError: false,
      filters: {
        bill_no: '',
        buyers: [],
        areas: [],
        statuses: [],
        delivery_mode: [],
        date: `${this.formatedDate(
          new Date(),
          'YYYY-MM-DD',
        )} to ${this.formatedDate(new Date(), 'YYYY-MM-DD')}`,
        createdBy: null,
        pickedBy: null,
        deliveredBy: null,
      },
      selectedBills: [],
      showModal: false,
      selectedViewId: null,
      isAllBillSelected: false,
      confirmPickModal: false,
      confirmPickAndDeliverModal: false,
      confirmDeliverModal: false,
      isNewItemAvailable: false,
      isRefreshMissingBills: false,
    }
  },
  computed: {
    parsedBills() {
      return this.results.map(bill => {
        let user = '-'
        const {
          generated_by: createUser = null,
          picked_by_fullname: pickedUser = null,
          delivered_by_fullname: deliveryUser = null,
          action_by: actionBy = null,
        } = bill

        if (actionBy) {
          user = actionBy
        } else if (deliveryUser) {
          user = deliveryUser
        } else if (!deliveryUser && pickedUser) {
          user = pickedUser
        } else if (!deliveryUser && !pickedUser) {
          user = createUser
        }
        return {
          ...bill,
          user,
        }
      })
    },
  },
  watch: {
    filters: {
      handler() {
        this.currentPage = 1
        this.fetchData()
      },
      deep: true,
    },
    showModal(newVal) {
      if (!newVal) {
        this.selectedViewId = null
      }
    },
    selectedBills(newVal) {
      if (this.results.length > 0 && newVal.length === this.results.length) {
        this.isAllBillSelected = true
      } else {
        this.isAllBillSelected = false
      }
    },
  },
  created() {
    WS.joinRoom(WS_ROOMS.DELIVERY_STATUS)
    bus.$on(WS_EVENTS.DELIVERY_BILL_CREATED, this.onNewBillCreated)
    bus.$on(WS_EVENTS.DELIVERY_BILL_UPDATED, this.onBillUpdated)
  },
  destroyed() {
    WS.leaveRoom(WS_ROOMS.DELIVERY_STATUS)
    bus.$off(WS_EVENTS.DELIVERY_BILL_CREATED, this.onNewBillCreated)
    bus.$off(WS_EVENTS.DELIVERY_BILL_UPDATED, this.onBillUpdated)
  },
  mounted() {
    this.fetchData()
    this.fetchBusOptions()
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    sortingChanged(ctx) {
      if (this.results.length < 1) {
        return
      }
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchData()
    },
    async fetchData() {
      try {
        this.loadingData = true
        this.loadingError = false
        this.errorMessage = null
        const {
          bill_no: billNumber, buyers, areas, statuses, date, createdBy, pickedBy, deliveredBy, delivery_mode,
        } = this.filters

        const [fromDate, toDate] = date.split(' to ')
        const preparedPayload = {
          sort_by: this.sortBy,
          sort_desc: this.sortDesc,
          page: this.currentPage,
          per_page: this.perPage,
          ...(billNumber ? { bill_no: billNumber } : null),
          areas,
          buyers,
          statuses,
          delivery_mode,
          ...(fromDate && toDate ? {
            date_from: fromDate,
            date_to: toDate,
          } : {}),
          created_by: createdBy,
          picked_by: pickedBy,
          delivered_by: deliveredBy,

        }
        const { data } = await axios.get(
          `/delivery/delivery_bills?${getParsedQueryString(
            preparedPayload,
          )}`,
        )
        this.results = data.results
        this.totalItems = data.count
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Failed to fetch bills!'
        this.loadingError = true
      } finally {
        this.loadingData = false
        this.isNewItemAvailable = false
        this.isRefreshMissingBills = false
      }
    },
    fetchBusOptions() {
      axios.get('/delivery/bus_options').then(res => {
        this.busOptions = res.data.results
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Failed to fetch bus Options!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    handleCloseModal() {
      this.selectedViewId = null
    },
    async handleViewClick(id) {
      this.selectedViewId = id
      this.showModal = true
    },
    async onStatusUpdate() {
      this.fetchData()
      this.selectedBills = []
    },
    handleAllBillSelectChange(isChecked) {
      this.selectedBills = isChecked
        ? this.results.map(data => data.id)
        : []
    },
    handleMarkAsPick() {
      this.confirmPickModal = true
    },
    handleMarkAsDeliver() {
      this.confirmDeliverModal = true
    },
    handlePickAndDeliver() {
      this.confirmPickAndDeliverModal = true
    },
    handleRefresh() {
      this.isRefreshMissingBills = true
      this.fetchData()
    },

    // Real time data event
    onNewBillCreated() {
      this.isNewItemAvailable = true
    },

    onBillUpdated(data) {
      this.results = this.results.map(result => {
        const availableItem = data.find(item => item.id === result.id)
        if (availableItem) {
          return {
            ...result,
            ...availableItem,
          }
        }
        return result
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.delivery-status-refresh-btn {
  position: relative;
  .badge {
    height: 9px;
    width: 9px;
    min-width: 1px;
    min-height: 1px;
    right: 17px;
    top: 14px;
  }

}
</style>
